import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

import Link from '@cof/graffiti-alley-spray-cans/atoms/Link';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO/SEO';

import './terms-of-use.scss';

const contentHelper = (sectionKey, contentGroupingNum, titleId, numOfP, strongP, pValues, pUnwrap) => {
  const titleKey = `title${contentGroupingNum}`;
  const paragraphKey = `p${contentGroupingNum}`;
  const paragraphs = [];

  for (let i = 0; i < numOfP; i++) {
    const pContentKey = numOfP <= 1 ? paragraphKey : `${paragraphKey}-${i}`;
    const text = <FormattedMessage id={`pages.terms-of-use.${sectionKey}.${pContentKey}`} values={pValues} />;
    const textEl = strongP?.includes(pContentKey) ? <strong>{text}</strong> : text;
    paragraphs.push(
      pUnwrap?.includes(pContentKey) ? <span key={pContentKey}>{textEl}</span> : <p key={pContentKey}>{textEl}</p>,
    );
  }

  const title = <FormattedMessage id={`pages.terms-of-use.${sectionKey}.${titleKey}`} />;
  const titleEl = titleId ? <h3 id={titleId}>{title}</h3> : <h3>{title}</h3>;
  return (
    <>
      {titleEl}
      {paragraphs}
    </>
  );
};

const tcAnchorLinks = {
  1: 'tac-introduction',
  2: 'tac-definitions',
  3: 'tac-description',
  4: 'tac-security-confidentiality',
  5: 'tac-electronic-communications',
  6: 'tac-disclosure',
  7: 'tac-changes-in-services',
  8: 'tac-privacy',
  9: 'tac-statement',
  10: 'tac-responsibilities',
  11: 'tac-amendment',
  12: 'tac-termination',
  13: 'tac-assignment',
  14: 'tac-no-waiver',
  15: 'tac-liability',
  16: 'tac-harm',
  17: 'tac-limitations',
  18: 'tac-severability',
  19: 'tac-governing-law',
};

const createTermsAndConditionsList = () => {
  const items = [];
  for (let i = 0; i < Object.keys(tcAnchorLinks).length; i++) {
    items.push(
      <li key={i}>
        <a href={`#${tcAnchorLinks[i + 1]}`}>
          <FormattedMessage id={`pages.terms-of-use.tc.tc-list-item.${i + 1}`} />
        </a>
      </li>,
    );
  }
  return <ol>{items}</ol>;
};

const createConsentListItems = () => {
  const items = ['p5-li0', 'p5-li1', 'p5-li2', 'p5-li3', 'p5-li4', 'p5-li5'];
  const itemElements = items.map((itemKey) => (
    <li key={itemKey}>
      <FormattedMessage id={`pages.terms-of-use.tc.${itemKey}`} />
    </li>
  ));
  return <ul>{itemElements}</ul>;
};

const dataSharingContentHelper = () => {
  const numParagraphs = 22;
  const paragraphValues = {
    p1: {
      privacy: (
        <Link to="/privacypolicy" target="_blank" rel="noopener" native="true">
          <FormattedMessage id="pages.terms-of-use.transaction-tc.p1-privacy" />
        </Link>
      ),
      tc: (
        <a href="#terms-and-conditions-text">
          <FormattedMessage id="pages.terms-of-use.transaction-tc.p1-tc" />
        </a>
      ),
    },
    p2: {
      datatc: (
        <a href="#datasharing-terms-conditions">
          <FormattedMessage id="pages.terms-of-use.transaction-tc.p2-datatc" />
        </a>
      ),
    },
    p4: {
      service: (
        <strong>
          <FormattedMessage id="pages.terms-of-use.transaction-tc.p4-service" />
        </strong>
      ),
    },
    p8: {
      dataRelease: (
        <strong>
          <FormattedMessage id="pages.terms-of-use.transaction-tc.p8-datarelease" />
        </strong>
      ),
    },
    p9: {
      unlink: (
        <strong>
          <FormattedMessage id="pages.terms-of-use.transaction-tc.p9-unlink" />
        </strong>
      ),
    },
    p11: {
      release: (
        <strong>
          <FormattedMessage id="pages.terms-of-use.transaction-tc.p11-release" />
        </strong>
      ),
    },
    p12: {
      exclusion: (
        <strong>
          <FormattedMessage id="pages.terms-of-use.transaction-tc.p12-exclusion" />
        </strong>
      ),
    },
    p14: {
      noWarranty: (
        <strong>
          <FormattedMessage id="pages.terms-of-use.transaction-tc.p14-nowarranty" />
        </strong>
      ),
    },
    p16: {
      privPractice: (
        <strong>
          <FormattedMessage id="pages.terms-of-use.transaction-tc.p16-privpractice" />
        </strong>
      ),
    },
    p17: {
      notices: (
        <strong>
          <FormattedMessage id="pages.terms-of-use.transaction-tc.p17-notices" />
        </strong>
      ),
    },
    p18: {
      support: (
        <strong>
          <FormattedMessage id="pages.terms-of-use.transaction-tc.p18-support" />
        </strong>
      ),
      contact: (
        <Link to="/contact-us/capitalonecards" target="_blank" rel="noopener" native="true">
          <FormattedMessage id="pages.terms-of-use.transaction-tc.p18-contact" />
        </Link>
      ),
    },
    p20: {
      lang: (
        <strong>
          <FormattedMessage id="pages.terms-of-use.transaction-tc.p20-lang" />
        </strong>
      ),
      em: (
        <em>
          <FormattedMessage id="pages.terms-of-use.transaction-tc.p20-em" />
        </em>
      ),
    },
    p21: {
      general: (
        <strong>
          <FormattedMessage id="pages.terms-of-use.transaction-tc.p21-general" />
        </strong>
      ),
    },
  };
  const italicizedParagraphs = ['p3'];

  const paragraphs = [];
  for (let i = 0; i < numParagraphs; i++) {
    const pKey = `p${i}`;
    const text = <FormattedMessage id={`pages.terms-of-use.transaction-tc.${pKey}`} values={paragraphValues[pKey]} />;
    const textEl = italicizedParagraphs.includes(pKey) ? <em>{text}</em> : text;
    paragraphs.push(<p key={pKey}>{textEl}</p>);
  }

  return paragraphs;
};

const TermsOfUse = injectIntl(({ intl }) => {
  const isFrench = intl.locale === 'fr';
  return (
    <Layout>
      <section className="page-content-wrapper terms-of-use">
        <div className="page-content">
          <h1>
            <FormattedMessage id="pages.terms-of-use.header" />
          </h1>
          <ul className="terms-content-list">
            <li>
              <a href="#terms-of-use-text">
                <FormattedMessage id="pages.terms-of-use.content-list.tu" />
              </a>
            </li>
            <li>
              <a href="#terms-and-conditions-text">
                <FormattedMessage id="pages.terms-of-use.content-list.tc" />
              </a>
            </li>
            <li>
              <a href="#datasharing-terms-conditions">
                <FormattedMessage id="pages.terms-of-use.content-list.transaction-tc" />
              </a>
            </li>
          </ul>
          <section id="terms-of-use-text">
            <h2>
              <FormattedMessage id="pages.terms-of-use.tu.header" />
            </h2>
            <p>
              <FormattedMessage id="pages.terms-of-use.tu.p" />
            </p>
            {contentHelper('tu', 0, null, 1)}
            {contentHelper('tu', 1, null, 1)}
            {contentHelper('tu', 2, null, 4)}
            {contentHelper('tu', 3, null, 1)}
            {contentHelper('tu', 4, null, 1)}
            {contentHelper('tu', 5, null, 1, [], {
              feedback: (
                <strong>
                  <FormattedMessage id="pages.terms-of-use.tu.p5-feedback" />
                </strong>
              ),
            })}
            {contentHelper('tu', 6, null, 2)}
            {contentHelper('tu', 7, null, 1)}
          </section>
          <section id="terms-and-conditions-text">
            <h2>
              <FormattedMessage id="pages.terms-of-use.tc.header" />
            </h2>
            {createTermsAndConditionsList()}
            {contentHelper('tc', 0, tcAnchorLinks[1], isFrench ? 10 : 9, [isFrench ? 'p0-8' : 'p0-7'], {
              obs: (
                <strong>
                  <FormattedMessage id="pages.terms-of-use.tc.p0-obs" />
                </strong>
              ),
              aa: (
                <strong>
                  <FormattedMessage id="pages.terms-of-use.tc.p0-aa" />
                </strong>
              ),
              url: (
                <Link to="/" target="_blank" rel="noopener" native="true">
                  <FormattedMessage id="pages.terms-of-use.tc.p0-url" />
                </Link>
              ),
            })}
            {contentHelper('tc', 1, tcAnchorLinks[2], isFrench ? 7 : 6, [], {
              c1: (
                <strong>
                  <FormattedMessage id="pages.terms-of-use.tc.p1-c1" />
                </strong>
              ),
              we: (
                <strong>
                  <FormattedMessage id="pages.terms-of-use.tc.p1-we" />
                </strong>
              ),
              us: (
                <strong>
                  <FormattedMessage id="pages.terms-of-use.tc.p1-us" />
                </strong>
              ),
              our: (
                <strong>
                  <FormattedMessage id="pages.terms-of-use.tc.p1-our" />
                </strong>
              ),
              you: (
                <strong>
                  <FormattedMessage id="pages.terms-of-use.tc.p1-you" />
                </strong>
              ),
              your: (
                <strong>
                  <FormattedMessage id="pages.terms-of-use.tc.p1-your" />
                </strong>
              ),
              vos: (
                <strong>
                  <FormattedMessage id="pages.terms-of-use.tc.p1-vos" />
                </strong>
              ),
              ap: (
                <strong>
                  <FormattedMessage id="pages.terms-of-use.tc.p1-ap" />
                </strong>
              ),
              c1site: (
                <strong>
                  <FormattedMessage id="pages.terms-of-use.tc.p1-c1site" />
                </strong>
              ),
              cpu: (
                <strong>
                  <FormattedMessage id="pages.terms-of-use.tc.p1-cpu" />
                </strong>
              ),
              ob: (
                <strong>
                  <FormattedMessage id="pages.terms-of-use.tc.p1-ob" />
                </strong>
              ),
            })}
            {contentHelper('tc', 2, tcAnchorLinks[3], 2, [], {
              services: (
                <strong>
                  <FormattedMessage id="pages.terms-of-use.tc.p2-services" />
                </strong>
              ),
            })}
            {contentHelper('tc', 3, tcAnchorLinks[4], 5, [], {
              ai: (
                <strong>
                  <FormattedMessage id="pages.terms-of-use.tc.p3-ai" />
                </strong>
              ),
            })}
            {contentHelper('tc', 4, tcAnchorLinks[5], 16, ['p4-3', 'p4-6'], {
              contactUs: (
                <Link to="/contact-us/capitalonecards/" target="_blank" rel="noopener" native="true">
                  <FormattedMessage id="pages.terms-of-use.tc.p4-contact" />
                </Link>
              ),
              aa: (
                <strong>
                  <FormattedMessage id="pages.terms-of-use.tc.p4-aa" />
                </strong>
              ),
              here: (
                <Link to="https://www.capitalone.com/supported-carriers/" target="_blank" rel="noopener" native="true">
                  <FormattedMessage id="pages.terms-of-use.tc.p4-here" />
                </Link>
              ),
            })}
            {contentHelper(
              'tc',
              5,
              tcAnchorLinks[6],
              21,
              ['p5-0', 'p5-1', 'p5-5', 'p5-9', 'p5-11', 'p5-13', 'p5-17'],
              {
                consentList: createConsentListItems(),
                contactUs: (
                  <Link to="/contact-us/capitalonecards/" target="_blank" rel="noopener" native="true">
                    <FormattedMessage id="pages.terms-of-use.tc.p4-contact" />
                  </Link>
                ),
              },
              ['p5-7'],
            )}
            {contentHelper('tc', 6, tcAnchorLinks[7], 3)}
            {contentHelper('tc', 7, tcAnchorLinks[8], 1, [], {
              privacy: (
                <Link to="/privacypolicy" target="_blank" rel="noopener" native="true">
                  <FormattedMessage id="pages.terms-of-use.tc.p7-privacy" />
                </Link>
              ),
            })}
            {contentHelper('tc', 8, tcAnchorLinks[9], 1)}
            {contentHelper('tc', 9, tcAnchorLinks[10], 4, ['p9-0'], {
              contactUs: (
                <Link to="/contact-us/capitalonecards/" target="_blank" rel="noopener" native="true">
                  <FormattedMessage id="pages.terms-of-use.tc.p4-contact" />
                </Link>
              ),
            })}
            {contentHelper('tc', 10, tcAnchorLinks[11], 1)}
            {contentHelper('tc', 11, tcAnchorLinks[12], 4)}
            {contentHelper('tc', 12, tcAnchorLinks[13], 1)}
            {contentHelper('tc', 13, tcAnchorLinks[14], 1)}
            {contentHelper('tc', 14, tcAnchorLinks[15], 1)}
            {contentHelper('tc', 15, tcAnchorLinks[16], 1)}
            {contentHelper('tc', 16, tcAnchorLinks[17], 4)}
            {contentHelper('tc', 17, tcAnchorLinks[18], 1)}
            {contentHelper('tc', 18, tcAnchorLinks[19], 1)}
          </section>
          <section id="datasharing-terms-conditions">
            <h2>
              <FormattedMessage id="pages.terms-of-use.transaction-tc.header" />
            </h2>
            {dataSharingContentHelper()}
          </section>
        </div>
      </section>
    </Layout>
  );
});

export default TermsOfUse;

/* eslint-disable react/prop-types */
export const Head = ({ pageContext }) => (
  <SEO titleKey="pages.terms-of-use.page-title" descriptionKey="pages.terms-of-use.page-desc" intl={pageContext.intl} />
);
/* eslint-enable react/prop-types */
